import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  
   @font-face {
    font-family: 'LucidaBrightItalic';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
      src: local('Lucida Bright Italic'), local('Lucida-Bright-Italic'),
    url('/fonts/LBRITEI.woff2') format('woff2'),
    url('/fonts/LBRITEI.woff') format('woff'),
    url('/fonts/LBRITEI.ttf') format('truetype');
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-family: Lato, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
      'Lucida Grande', sans-serif;
    font-size: 100%;
    font-weight: 400;
    height: 100%;
    line-height: 1.4;
    scroll-behavior: smooth;
  }

  body {
    background-color: #ffffff;
    cursor: default;
    font-size: 1rem;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    color: #000025;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    min-height: 100vh;
    height: 100%;
    margin: 0;
  }

  #___gatsby, 
  #gatsby-focus-wrapper {
    min-height: 100vh;
    //height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }

`

export default GlobalStyle
