import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FormattedMessage, InjectedIntl } from 'react-intl'
import { lighten } from 'polished'
import languages from '../locale'
import { Location, WindowLocation } from '@reach/router'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Wrapper = styled.header`
  background-color: #6fd8b2;
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
  border-top: 12px solid #fff;
  flex-shrink: 0;
`

const Links = styled.ul`
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  margin: 0;
  padding: 20px 0;
`
const ScrollLink = styled(Link)`
  align-items: stretch;
  color: #444444;
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  padding: 7px 0;
  transition: background-color 100ms ease-in-out;

  @media (min-width: 640px) {
    padding: 7px 14px;
  }

  &--active,
  &:hover {
    background-color: ${lighten(0.15, '#6FD8B2')};
  }
`

const NavLink = styled(Link)`
  align-items: stretch;
  color: #444444;
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  padding: 7px 0;
  transition: background-color 100ms ease-in-out;

  @media (min-width: 640px) {
    padding: 7px 14px;
  }

  &--active,
  &:hover {
    background-color: ${lighten(0.15, '#6FD8B2')};
  }
`

const Ordinal = styled.span`
  display: none;
  font-size: 0.5rem;
  letter-spacing: 2px;

  @media (min-width: 640px) {
    display: inline;
  }
`

const Divider = styled.div`
  background-image: linear-gradient(to top left, rgba(0, 0, 0, 0) 45%, #153048, rgba(0, 0, 0, 0) 55%);
  display: none;
  margin-right: 8px;
  width: 10px;

  @media (min-width: 640px) {
    display: block;
  }
`

const Title = styled.span`
  display: none;
  font-size: 0.5rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @media (min-width: 640px) {
    display: block;
  }
`

const Text = styled.span`
  display: block;
  color: #153048;
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 0.7rem;
  padding-top: 6px;

  @media (min-width: 640px) {
    font-size: 1rem;
  }
`

const ItemDivider = styled.li`
  background-image: linear-gradient(to top left, rgba(0, 0, 0, 0) 45%, #fff, rgba(0, 0, 0, 0) 55%);
  display: block;
  width: 18px;

  @media (min-width: 640px) {
    display: none;
  }
`

interface Props {
  intl: InjectedIntl
}

interface PathProps {
  intl: InjectedIntl
  location: WindowLocation
}

const getPath = ({ intl, location }: PathProps): string => {
  let path: string
  if (intl.locale === languages[1]) {
    path = location.pathname.replace('/de/', '/')
  } else {
    path = location.pathname.replace('/', '/de/')
  }
  return path
}

const Header: React.FC<Props> = ({ intl }) => (
  <Wrapper>
    <nav>
      <Links>
        <li>
          <ScrollLink to={intl.locale === 'de' ? 'de/#a-story' : '/#a-story'}>
            <Ordinal>01</Ordinal>
            <Divider />
            <div>
              <Title>
                <FormattedMessage id="story_line" />
              </Title>
              <Text>
                <FormattedMessage id="story_title" />
              </Text>
            </div>
          </ScrollLink>
        </li>
        <ItemDivider />
        <li>
          <ScrollLink to={intl.locale === 'de' ? 'de/#the-plan' : '/#the-plan'}>
            <Ordinal>02</Ordinal>
            <Divider />
            <div>
              <Title>
                <FormattedMessage id="plan_line" />
              </Title>
              <Text>
                <FormattedMessage id="plan_title" />
              </Text>
            </div>
          </ScrollLink>
        </li>
        <ItemDivider />
        <li>
          <ScrollLink to={intl.locale === 'de' ? 'de/#the-venues' : '/#the-venues'}>
            <Ordinal>03</Ordinal>
            <Divider />
            <div>
              <Title>
                <FormattedMessage id="venue_line" />
              </Title>
              <Text>
                <FormattedMessage id="venue_title" />
              </Text>
            </div>
          </ScrollLink>
        </li>
        <ItemDivider />
        <li>
          <Location>
            {({ location }) => {
              return (
                <NavLink to={getPath({ intl, location })}>
                  <Ordinal>04</Ordinal>
                  <Divider />
                  <div>
                    <Title>{intl.locale === 'en' ? 'Verstehst nix' : 'Dont get it'}</Title>
                    <Text>{intl.locale === 'en' ? 'Deutsch' : 'English'}</Text>
                  </div>
                </NavLink>
              )
            }}
          </Location>
        </li>
      </Links>
    </nav>
  </Wrapper>
)

export default Header
