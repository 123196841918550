import React, { ReactNode } from 'react'
import { injectIntl, InjectedIntl } from 'react-intl'
import { Header, Footer, SEO } from '../components'
import styled from 'styled-components'
import GlobalStyle from '../globalStyles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  @media (min-width: 420px) {
    padding: 0;
  }
`

interface Props {
  children: ReactNode
  intl: InjectedIntl
}

const Layout: React.FC<Props> = ({ intl, children }) => (
  <>
    <GlobalStyle />
    <SEO intl={intl} />
    <Header intl={intl} />
    <Container>{children}</Container>
    <Footer />
  </>
)

export default injectIntl(Layout)
