import React from 'react'
import styled from 'styled-components'

const Container = styled.footer`
  background-color: #6fd8b2;
  font-weight: 300;
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
  border-bottom: 12px solid #fff;
  text-align: center;
  font-size: 12px;
  padding: 10px 0;
  flex-shrink: 0;
  margin-top: 50px;
`

const Footer: React.FC = () => <Container>Made with love by Sarah & Justin</Container>
export default Footer
