import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { InjectedIntl } from 'react-intl'

interface Props {
  intl: InjectedIntl
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleAlt
        shortName
        author
        url
        description
        banner
        twitter
      }
    }
  }
`

const SEO: React.FC<Props> = ({ intl }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { title, titleAlt, shortName, url, description, banner, twitter },
      },
    }) => {
      const seo = {
        title: intl.formatMessage({ id: 'title' }) || title,
        description: intl.formatMessage({ id: 'title' }) || description,
        image: `${url}${banner}`,
        url: `${url}${'/'}`,
      }
      const schemaOrgJSONLD = [
        {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          '@id': url,
          url: url,
          name: title,
          alternateName: titleAlt || '',
        },
      ]
      return (
        <>
          <Helmet title={seo.title}>
            <html lang={intl.locale} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="apple-mobile-web-app-title" content={shortName} />
            <meta name="application-name" content={shortName} />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

            {/* OpenGraph  */}
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitter} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO
