import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const Container = styled.h2`
  font-family: Heebo, serif;
  text-align: center;
  font-weight: 500;
  font-size: 2.5rem;
  margin: 4rem 0;
  padding: 0 15px;
  text-transform: uppercase;
  letter-spacing: -0.1rem;

  @media (min-width: 420px) {
    padding: 0;
  }
`

interface Props {
  id: string
}

const Heading: React.FC<Props> = ({ id }) => {
  return (
    <Container>
      <FormattedMessage id={id} />
    </Container>
  )
}

export default Heading
